
// --------------------------- storage

export class LocalStorage{
  constructor(){
    
  }

  static get allItem(){
    const storage = window.localStorage;
    const keys = Object.keys(storage);
    if(!keys || !keys.length) return null;
    const items = {};

    for (const key of Object.keys(storage)) {
      const value = JSON.parse(storage[key])
      items[key] = value && value.value;
    }
    return items;
  };

  static #checkKey = function(key){
    key = key.trim();
    if(typeof key !== 'string') throw new Error('存储key必须是string');
    if( !key ) throw new Error('无效key');
    return key;
  }

  static setItem(key, value){
    key = this.#checkKey(key);
    if(!Object.keys(window.localStorage).includes(key)) this.storageItem(key, value);

    value = {
      type: typeof value,
      value
    }
    window.localStorage.setItem(key, JSON.stringify(value));
    return { key, value };
  }

  static storageItem(key, value){
    key = this.#checkKey(key);
    if(Object.keys(window.localStorage).includes(key)) throw new Error(`存储key:${key}已存在存在`);

    value = {
      type: typeof value,
      value
    }
    window.localStorage.setItem(key, JSON.stringify(value));
    return { key, value };
  }

  static getItem(key){
    key = this.#checkKey(key);
    if(!Object.keys(window.localStorage).includes(key)) return undefined;

    const value = JSON.parse(window.localStorage.getItem(key));
    return value && value.value;
  }

  static removeItem(key){
    key = key.trim();
    if(typeof key !== 'string') throw new Error('存储key必须是string');
    window.localStorage.removeItem(key);
   
  }

  static clear(){
    window.localStorage.clear();
  }
}