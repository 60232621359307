<template>
  <div class="navbox">
    <div  @click="navto()" class="clickbox" >
      <div style="100%">
        <img :src='imagesrc' class="siteimg">
      </div>
    </div>
    <div class="content">
        <P><strong>{{name}}</strong></P>
        <!-- <div class="bg" @click="copy(),createmessagebox('复制成攻')"> <P class="src" >{{src}}</P></div> -->
    </div>

  </div>
</template>

<script>


export default {
  data() {
    return {
        
    }
  },
  props:{
    imagesrc:String,
    name:String,
    src:String,
    details:String,
    
    },
    inject:['createmessagebox'],
    methods:{
      navto(){
        window.open(this.src,'_blank');
      },
      copy(){
        let i= document.createElement('input');
        i.value=this.src;
        try{
          document.body.appendChild(i);             
          i.select();
          document.execCommand('copy');
          document.body.removeChild(i);
        }
        catch(e){
          alert(e);
      }
            
    }
  }

}
</script>

<style scoped>
  .navbox{
    width: 15rem;
    height: 15rem;
    color:#bfbf9a;
    text-align: center;
    border-radius: 12px;
    display: block;
    overflow: hidden;
    padding: 8px;
  }
  .clickbox{
    width: 15rem;
    height: 8rem; 
  }
  .content{
    padding: 0.2rem;
    border-radius: 12px;
    /* background-color: #1d1d1d; */
    margin:0.5rem 0rem;
    height: 5rem;
    /* box-shadow: 0px 2px 1px 1px #2c3e50; */
  }
  .content p{
    text-overflow: ellipsis;
    overflow: hidden;
      
  }
  .src{
    font-weight: 100px;
    font-size: small;
    transition: background-color 0.5s,font-size 0.1s, ;
    padding: 0.2rem;
    margin: none;
  }
  .bg:hover p{
    background-color:#4e4e4e;
    border-radius: 2px;
    overflow: visible;               
    z-index: 990;
    display: inline;
    color:khaki;
  }
  
  .siteimg{
    margin: auto;
    text-align: center;
    width: 8rem;
    height: 8rem;
    border-radius: 12px;
    transition:  box-shadow 1s,width 0.5s,height 0.5s;
    box-shadow: 0px 0px 5px 5px #2c3e50;
  }


  
  .siteimg:hover{      
    box-shadow: 0px 0px 15px 5px #5d5d5d;
    width: 9rem;
    height: 9rem;
    display: inline;
  }
    

</style>