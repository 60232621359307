<template>
<div class="vr-block">
  <h3> 暂不支持 </h3>
  <p> No support, comming soon.</p>
</div>
</template>

<script>
export default {
  name: "VR Index"
}
</script>

<style scoped>
.vr-block{
  padding: 16px;
}
</style>