<template>
<div class="footer-block">
  <div class="footer-content">
    Copyright © 2023-{{ new Date().getFullYear() }}
    猫猫宅技术 <el-link href="http://www.soubzhong.com">www.soubzhong.com</el-link>
    备案号：<el-link @click="open('https://beian.miit.gov.cn')" style="font-size:0.8rem"> 粤ICP备 2023012760 号</el-link>
  </div>
</div>
</template>
  
<script>
export default {
  name: "ViewPageFooter",
  methods: {
    open(src) {
      window.open(src)
    }
  }
}
</script>

<style scoped>
.footer-block {
  display: flex;
  width: 100%;
  justify-content: center;
  box-shadow: 0px -1px 1px 0px var(--app-border-color, #7d7d7d);
  margin-top: 3rem;
  background-color: transparent;
}
.footer-content {
  font-size: 0.8rem;
  color: var(--app-seccolor, #7d7d7d);
  padding: 16px 0px;
  text-align: center;
  line-height: 1.5rem;
  width: 297mm;
}
</style>