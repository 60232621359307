import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css';
import 'element-plus/theme-chalk/display.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import * as defineApp from '@/lib/app.js'
import { getDefaultThemelist } from '@/lib/theme/theme_list.js'

//
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
  ToolboxComponent,
  MarkLineComponent,
  MarkPointComponent
} from 'echarts/components';
// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { SVGRenderer } from 'echarts/renderers';
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LabelLayout,
  UniversalTransition,
  SVGRenderer,
  ToolboxComponent,
  LegendComponent,
  MarkLineComponent,
  MarkPointComponent,
  
  LineChart
]);

// -----------------组件和插件
const app = createApp(App);
app.use(router);
app.use(ElementPlus);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}


// -----------------全局属性
app.config.globalProperties.$echarts = echarts;
app.config.globalProperties.$three = {    // three相关 
  scenes: new Array(),      
};
app.config.globalProperties.$app = {    // app 应用属性
  theme: new defineApp.AppTheme(),
  defaultThemelist: getDefaultThemelist(),
  storage: defineApp.AppStorage,
}

// ----------------全局捕错
let errMessage='';
app.config.errorHandler= err => {
  if(err.message === errMessage) {
    console.log('同一个错误');
  }else{
    errMessage = err.message;
    alert(`错误：${err.message}`);
    console.log(err);
  }
}

app.mount('#app');
