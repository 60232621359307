<template>
<view-page :size="PAGESIZE.SMALL">
<div class="index-view">
  <div>
    <el-image :src="require('@/assets/images/map@1x.png')"> </el-image>
  </div>

  <div style="text-align: center;"><p>网址导航</p><hr/></div>
  <div style="width: 100%; display: flex; flex-wrap: wrap; justify-content: center;">
    <nav-box v-for="site in sites" :key='site' :name="site.name" :imagesrc="site.imagesrc" :src="site.src" ></nav-box>
  </div>
</div>
</view-page>

</template>

<script>
import { PAGESIZE } from "@/lib/constant.js";
import ViewPage from "@/components/ViewPage.vue";
import NavBox from '@/components/NavBox.vue';

export default {
  name: '2dIndex',
  components: { ViewPage, NavBox },
  
  mounted(){
  },
 
  data(){
    return {
      PAGESIZE: PAGESIZE,
      sites:[
        {imagesrc:require('@/assets/images/sitesico/logo.png'),name:'Vue',src:'https://cn.vuejs.org/',details:'ss'},
        {imagesrc:require('@/assets/images/sitesico/cainiao.png'),name:'菜鸟教程',src:'https://www.runoob.com/',details:'ss'},
        {imagesrc:require('@/assets/images/sitesico/es6rumen.png'),name:'Es6入门-阮一峰',src:'https://es6.ruanyifeng.com/#docs/asynciterator',details:'ss'},
        {imagesrc:require('@/assets/images/sitesico/webpack.png'),name:'Webpack.js',src:'https://webpack.docschina.org/',details:'ss'},
        {imagesrc:require('@/assets/images/sitesico/jqueryapi.png'),name:'JQueryapi',src:'https://www.jquery123.com/',details:'ss'},
        {imagesrc:require('@/assets/images/sitesico/pdf.png'),name:'pdf加载js',src:'https://mozilla.github.io/pdf.js/#',details:'ss'},
        {imagesrc:require('@/assets/images/sitesico/lvwazi.png'),name:'GreenSock.',src:'https://greensock.com/',details:'ss'},
        {imagesrc:require('@/assets/images/sitesico/tweenmax.png'),name:'TweenMax.',src:'https://www.tweenmax.com.cn//',details:'ss'},
        {imagesrc:require('@/assets/images/sitesico/donghua.png'),name:'Animate.css',src:'https://animate.style/',details:'ss'},
        {imagesrc:require('@/assets/images/sitesico/w3c.png'),name:'w3c-JavaScript',src:'https://www.w3schools.com/jsref/met_document_importnode.asp',details:'ss'},
        {imagesrc:require('@/assets/images/sitesico/wecgljichu.png'),name:'WebGL理论基础',src:'https://webglfundamentals.org/webgl/lessons/zh_cn/',details:'ss'},
        {imagesrc:require('@/assets/images/sitesico/keji.png'),name:'abbazabacannoli的柯基',src:'https://corgiorgy.com/',details:'ss'},
        {imagesrc:require('@/assets/images/sitesico/taikong.png'),name:'宇宙时空播放器',src:'http://www.lightyear.fm/',details:'ss'},
        {imagesrc:require('@/assets/images/sitesico/rockstar.png'),name:'Rockstar Gmaes Social Club',src:'https://socialclub.rockstargames.com/photo/gtav/IEciwNE99k6lroAk1NHLsQ',details:'ss'},
        {imagesrc:require('@/assets/images/sitesico/flycat.png'),name:'猫猫宅技术',src:'http://www.soubzhong.com',details:'ss'},
        ],
    }
  },
  computed:{
  },
}
</script>

<style scoped>
.index-view{
  color: var(--app-color, white);
}
</style>