<template>
<div class="scene-block">
  <el-row v-if="showHeader" class="header">
    <el-col :span="24">
      <slot name="header">
        <h4>{{sceneId}}</h4>
      </slot>
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="24">
      <div :id="sceneId">
      </div>
    </el-col>
  </el-row>
   <el-row>
    <el-col :span="24">
      <slot name="footer"></slot>
    </el-col>
  </el-row>
</div>
 
</template>
<script>
import * as THREE from 'three';
import { ThreeScene, ThreeError } from '@/lib/three/three_app.js';

export default {
  name: "ThreeScene",
  
  async created(){
    try {
      if(!this.sceneId) throw new Error('没有指定正确的场景id');
      const sceneIdList = this.$three.scenes.filter(item => item.sceneId === this.sceneId);
      if( !sceneIdList || !sceneIdList.length ) this.$three.scenes.push( new ThreeScene(this.sceneId) );
    } catch (err) {
      throw new ThreeError(err ? err.message:"three app 发生异常!")
    }   
  },
  mounted(){
    this.start(this.sceneId);
  },

  emits: ["click"],

  props:{
    // 场景Id,必传
    sceneId: {
      type: String,
      required: true,
    },
    sceneCodeSrc: {
      type: String,
    },
    showHeader: {
      type: Boolean,
      default: true,
    }
  },

  data(){

  },

  methods:{
    start(id){
      const moutePointer = document.getElementById(id);
      if( !moutePointer ) throw new Error("无效挂载点:"+id);

      const scene = new THREE.Scene();
      scene.background = new THREE.Color('skyblue');
      const camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 );

      const renderer = new THREE.WebGLRenderer();
      renderer.setSize( window.innerWidth, window.innerHeight );
      moutePointer.appendChild( renderer.domElement );

      const geometry = new THREE.BoxGeometry( 1, 1, 1 );
      const material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
      const cube = new THREE.Mesh( geometry, material );
      scene.add( cube );

      camera.position.z = 5;

      function animate() {
        requestAnimationFrame( animate );

        cube.rotation.x += 0.01;
        cube.rotation.y += 0.01;

        renderer.render( scene, camera );
      };

      return animate();
    }
  }

}
</script>
<style scoped>
.scene-block .header{
  padding: 0px 12px;
}
</style>