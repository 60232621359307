<template>
  <div id="app-theme"></div>
  <router-view></router-view> 
</template>
<script>
  export default {
    name: 'App',
    mounted() {
      this.$app.theme.laodStroge();
      if (this._isMobile()) {
        // this.$router.replace('/m_index');
      } else {
        // this.$router.replace('/pc_index');
      }
    },

    methods:{
      _isMobile() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        return flag;
      }
    }
  }
</script>
<style>
#app {
  background-color: var(--app-background-color, #191919);
  font-size: 1rem;
  color: var(--app-color, #dbdbdb);
  font-family: "Avenir,Helvetica,Arial,sans-serif";
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100vh;
  margin: 0%;
  padding: 0%;
  /* overflow: hidden; */
}

div {
  box-sizing: border-box;
}

</style>
