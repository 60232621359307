<template>
  <view-page-header></view-page-header>
  <div class="page-block">
    <div :class="pageSize">
      <slot>
        <strong>This is a {{ pageSize }} page!</strong>
      </slot>
    </div>
  </div>
<view-page-footer></view-page-footer>
</template>
<script>
import { PAGESIZE } from "@/lib/constant.js"
import ViewPageHeader from "@/components/ViewPageHeader.vue";
import ViewPageFooter from "@/components/ViewPageFooter.vue";

export default {
  name: "ViewPage",
  props: {
    size: {
      type: Number,
      default: PAGESIZE.MIDDLE,
    }
  },
  data() {
    return {
      PAGESIZE: PAGESIZE,
    }
  },
  components: { ViewPageHeader,  ViewPageFooter},
  computed: {
    pageSize() {
      if (!Object.values(this.PAGESIZE).includes(+this.size)) {
        console.log('没有此页面尺寸枚举：' + this.size);
        return 'page-content-middel';
      }
      else if (+this.size === this.PAGESIZE.SMALL) return 'page-content-small';
      else if (+this.size === this.PAGESIZE.BIG) return 'page-content-big';
      else return 'page-content-middel';
    }
  }
}
</script>

<style scoped>
.page-block {
  width: 100%;
  display: flex;
  justify-content: center;
}

.page-block .page-content-small{
  width: 210mm;
}
.page-block .page-content-middel{
  width: 297mm;
}
.page-block .page-content-big{
  width: 320mm;
}
</style>

