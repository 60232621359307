/**
 * 系统枚举
 */

export const PAGESIZE = {
  SMALL: 1,
  MIDDLE: 2,
  BIG: 3,
}

// 冻结对象
Object.freeze(PAGESIZE);

export default {PAGESIZE}