export class ThreeScene{
  constructor(sceneId){
    this.sceneId = sceneId;   //  场景id
  }

}

export class ThreeError extends Error{
  constructor(message){
    super('Three App Error:'+ message);
  }
}