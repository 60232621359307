<template>
<div class="index-view" >
  <three-scene sceneId="3d-index-scene"></three-scene>
</div>
</template>

<script>
import ThreeScene from '@/components/ThreeScene.vue'

export default {
  name: '3D Index',
  
  components: { ThreeScene },
  
  mounted(){
    // three("scene");

  },
}
</script>

<style scoped>
.index-view{
  height: 100vh;
  padding: 0px;
  overflow: auto;
}
</style>