import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Index2D from '../views/pc/2d/Index.vue'
import Index3D from '../views/pc/3d/Index.vue'
import IndexVr from '../views/pc/vr/Index.vue'

// 路径复用
const pc2D = `/2d/index`;

const routes = [
  {
    path: '/',
    component: Index2D
  },
  {
    path: '/:paramPath(.*)',
    name: '404',
    component: import('@/views/PageNotFound.vue')
  },
  {
    path: pc2D,
    component: Index2D
  },
  {
    path: '/3d/index',
    component: Index3D
  },
  {
    path: '/vr/index',
    component: IndexVr
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
