import {  LocalStorage as  AppStorage } from "@/lib/storage/LocalStorage.js";
import { getDefaultThemelist } from "@/lib/theme/theme_list.js";

export class AppTheme{
  #fields = {
    themeName : "light",
    fontSize : '1em',
    fontWheight : 'lighter',
    color: 'black',
    secColor: 'black',
    borderColor : 'black',
    titleColor : 'black',
    linkColor : 'blue',
    backgroundColor : 'white',
  }
  constructor(args){
    this.#fields = Object.assign(this.#fields, args);

    this.themeName = this.#fields.themeName;
    this.fontSize = this.#fields.fontSize;
    this.fontWheight = this.#fields.fontWheight;
    this.color = this.#fields.color;
    this.secColor = this.#fields.secColor;
    this.borderColor = this.#fields.borderColor;
    this.titleColor = this.#fields.titleColor;
    this.linkColor = this.#fields.linkColor;
    this.backgroundColor = this.#fields.backgroundColor;
    
  }

  laodStroge(){
    const storageThemeName =  AppStorage.getItem('themeName');
    if(storageThemeName){
      const currentTheme = getDefaultThemelist().find( item => item.themeName === storageThemeName );
      if(currentTheme) this.setTheme(currentTheme);
    }
  }

  setTheme(args){
    this.#fields = Object.assign(this.#fields, args);

    this.themeName = this.#fields.themeName;
    this.fontSize = this.#fields.fontSize;
    this.fontWheight = this.#fields.fontWheight;
    this.color = this.#fields.color;
    this.secColor = this.#fields.secColor;
    this.linkColor = this.#fields.linkColor;
    this.titleColor = this.#fields.titleColor;
    this.borderColor = this.#fields.borderColor;
    this.backgroundColor = this.#fields.backgroundColor;

    AppStorage.setItem('themeName',  this.themeName);
    this.update();
  }

  update(){
    const ele = document.getElementById("app-theme");
    if(!ele) return;
    ele.innerHTML = `
    <style>
      :root{
        --app-color:${this.color};
        --app-seccolor:${this.secColor};
        --app-link-color:${this.linkColor};
        --app-title-color:${this.titleColor};
        --app-border-color:${this.borderColor};
        --app-background-color:${this.backgroundColor};
      }
    </style>`
  }
}