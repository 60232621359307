<template>
<div class="header-block">
  <div class="header-contend">
    <div class="ico-box">
      <div class="header-ico-parent">
        <el-image :src="require('@/assets/images/headerico.png')" class="header-ico" :fit="contain"></el-image>
      </div>
      <div class="header-font hidden-sm-and-down" >
        <h3>soubzhong.com</h3>
      </div>
    </div>
    
    <div  class="last-box">
      <!-- 主题:  -->
      <!-- <strong>主题</strong>-->
      <el-select v-model="theme" @change="themeChange" placeholder="主题">
        <el-option v-for=" item in  themeList" :key="item.themeName" :label="item.themeName" :value="item.themeName"></el-option>
      </el-select> 
    </div>

  </div>
  

</div>
</template>

<script>
export default {
  name: "ViewPageHeader",
  mounted(){
    this.theme = this.$app.theme.themeName;
  },

  data(){
    return{
      theme: "",
    }
  },

  computed:{
    themeList(){
      return this.$app.defaultThemelist || [];
    }
  },

  methods:{
    themeChange(){
      const currentTheme = this.themeList.find( item => item.themeName === this.theme );
      this.$app.theme.setTheme(currentTheme);
    }
  }

}
</script>

<style scoped>
.header-block{
  padding: 8px 0px;
  margin-bottom: 8px;
  border-color: var(--app-border-color, black);
  background-color: transparent;
  box-shadow: 0px 1px 2px 0px var(--app-border-color, #7d7d7d);
}
.header-contend {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 297mm;
  margin-left: auto;
  margin-right: auto;
}
.header-block .ico-box{
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0px 8px;
  height: 3em;
}


.ico-box .header-ico-parent{
  height: 100%;
  align-items: center;
}

.ico-box .header-ico-parent .header-ico{
  height: 100%;
  width: 100%;
}

.ico-box .header-font{
  letter-spacing: 1px;
  color: var(--app-title-color, white);
  align-content: flex-start;
}

.header-block .message-box{
  padding: 0px 8px;
  height: 100%;
}
.header-block .message-box p{
  height:3em;
  border-style: inset;
  border-width: 2px;
  border-color: var(--app-border-color, #dbdbdb);
  border-radius: 14px;
  padding: 0.5em;
  background-color: transparent;
  margin: 0px;
}

.header-block .last-box{
  padding: 0px 8px;
}
.header-block .last-box > strong {
  padding: 0px 4px;
  cursor: pointer;
  text-decoration: underline;
}
.header-block .last-box > strong:hover {
  color: var(--app-link-color);
}
</style>