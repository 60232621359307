import { AppTheme } from "./AppTheme.js"

export function getDefaultThemelist(){
  return [
    new AppTheme({
      themeName : "默认",
      fontSize : '1em',
      fontFamily: 'Avenir,Helvetica,Arial,sans-serif',
      fontWheight : 'normal',
      color: '#dbdbdb',
      secColor: '#7d7d7d',
      borderColor : '#7d7d7d',
      titleColor : 'white',
      linkColor : '#d9ca79',
      backgroundColor : '#191919',
    }),
    new AppTheme({
      themeName : "光亮",
      fontSize : '1em',
      fontWheight : 'lighter',
      color : 'black',
      borderColor : 'black',
      titleColor : 'black',
      linkColor : 'blue',
      backgroundColor : 'white',
    }),
    new AppTheme({
      themeName : "dark",
      fontSize : '1em',
      fontWheight : 'lighter',
      color : 'white',
      borderColor : 'white',
      titleColor : 'white',
      linkColor : 'blue',
      backgroundColor : 'darkslateblue',
    }),
    new AppTheme({
      themeName : "实木",
      fontSize : '1em',
      fontWheight : 'lighter',
      color : 'white',
      borderColor : 'white',
      titleColor : 'white',
      linkColor : 'blue',
      backgroundColor : 'burlywood',
    }),
    new AppTheme({
      themeName : "绿豆沙",
      fontSize : '1em',
      fontWheight : 'lighter',
      color : 'white',
      borderColor : 'white',
      titleColor : 'white',
      linkColor : 'blue',
      backgroundColor : '#C7EDCC',
    }),
    new AppTheme({
      themeName : "杏仁黄",
      fontSize : '1em',
      fontWheight : 'lighter',
      color : 'white',
      borderColor : 'white',
      titleColor : 'white',
      linkColor : 'blue',
      backgroundColor : '#FAF9DE',
    }),
    new AppTheme({
      themeName : "秋叶褐",
      fontSize : '1em',
      fontWheight : 'lighter',
      color : 'white',
      borderColor : 'white',
      titleColor : 'white',
      linkColor : 'blue',
      backgroundColor : '#FFF2E2',
    }),
    new AppTheme({
      themeName : "胭脂红",
      fontSize : '1em',
      fontWheight : 'lighter',
      color : 'white',
      borderColor : 'white',
      titleColor : 'white',
      linkColor : 'blue',
      backgroundColor : '#FDE6E0',
    }),
    new AppTheme({
      themeName : "海天蓝",
      fontSize : '1em',
      fontWheight : 'lighter',
      color : 'white',
      borderColor : 'white',
      titleColor : 'white',
      linkColor : 'blue',
      backgroundColor : '#DCE2F1',
    }),
    new AppTheme({
      themeName : "葛巾紫",
      fontSize : '1em',
      fontWheight : 'lighter',
      color : 'white',
      borderColor : 'white',
      titleColor : 'white',
      linkColor : 'blue',
      backgroundColor : '#E9EBFE',
    }),
    new AppTheme({
      themeName : "极光灰",
      fontSize : '1em',
      fontWheight : 'lighter',
      color : 'white',
      borderColor : 'white',
      titleColor : 'white',
      linkColor : 'blue',
      backgroundColor : '#EAEAEF',
    }),
    new AppTheme({
      themeName : "青草绿",
      fontSize : '1em',
      fontWheight : 'lighter',
      color : '#909399',
      borderColor : '#909399',
      titleColor : '#606266',
      linkColor : 'blue',
      backgroundColor : '#E3EDCD',
    }),
    new AppTheme({
      themeName : "电脑管家",
      fontSize : '1em',
      fontWheight : 'lighter',
      color : 'white',
      borderColor : 'white',
      titleColor : 'white',
      linkColor : 'blue',
      backgroundColor : '#CCE8CF',
    }),
    new AppTheme({
      themeName : "WPS护眼色",
      fontSize : '1em',
      fontWheight : 'lighter',
      color : 'white',
      borderColor : 'white',
      titleColor : 'white',
      linkColor : 'blue',
      backgroundColor : '#6E7B6C',
    }),
  ]
};